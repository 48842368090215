import React from 'react'
import { Sidenav, Nav } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import './SideNav.css';
import { NavLink } from "react-router-dom";

function SideNav() {
  return (
    <>
       <div className="sidenav" style={{ width: 240 }}>
            <Sidenav defaultOpenKeys={['3', '4']}>
            <Sidenav.Body>
                <Nav activeKey="1">
                {/* <Nav.Item eventKey="1" icon={<DashboardIcon />}>
                    Learn To Code
                </Nav.Item> */}
                {/* <Nav.Item eventKey="2" icon={<GroupIcon />}>
                    By Robin kaushik
                </Nav.Item> */}
                <Nav.Menu eventKey="1" title="JavaScript" icon={<MagicIcon />}>
                    <Nav.Item eventKey="1-2"><NavLink to="/javascript">JavaScript History and Introduction</NavLink></Nav.Item>
                    <Nav.Item eventKey="1-3">ES6</Nav.Item>
                    <Nav.Item eventKey="1-4">Map</Nav.Item>
                    <Nav.Item eventKey="1-5">Filter</Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="2" title="ReactJs" icon={<MagicIcon />}>
                    <Nav.Item eventKey="2-1"><NavLink to="/reactjs">Props in ReactJs</NavLink></Nav.Item>
                    <Nav.Item eventKey="2-2">ES6</Nav.Item>
                    <Nav.Item eventKey="2-3">Map</Nav.Item>
                    <Nav.Item eventKey="2-4">Filter</Nav.Item>
                </Nav.Menu>
                {/* <Nav.Menu eventKey="4" title="Settings" icon={<GearCircleIcon />}>
                    <Nav.Item eventKey="4-1">Applications</Nav.Item>
                    <Nav.Item eventKey="4-2">Channels</Nav.Item>
                    <Nav.Item eventKey="4-3">Versions</Nav.Item>
                    <Nav.Menu eventKey="4-5" title="Custom Action">
                    <Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
                    <Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
                    </Nav.Menu>
                </Nav.Menu> */}
                </Nav>
            </Sidenav.Body>
            </Sidenav>
        </div>
    </>
  )
}

export default SideNav