import React, { useState } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Main from "./Main";
import './Home.css'
import Blog from "./Blog";
import Footer from "./Footer";
import Nav from "./Nav";

function Home(props) {
  const [toggleMenu,setToggleMenu]=useState(false)
  const [headerResp, setHeaderResp] = useState(false);
  function handleSideNav(resp) {
    if (resp) {
      setHeaderResp(resp);
    } else {
      setHeaderResp(resp);
    }
    
  }
  
  return (
    <>
    <div className="home">
    <Header data={handleSideNav} />
      
     <Nav/>
       {headerResp? <SideNav/> : null}
       <Blog/>
       <Footer/>
    </div>
      
    </>
  );
}

export default Home;
