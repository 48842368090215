import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';

const mainFeaturedPost = {
  title: 'JavaScript History and Introduction',
  description:
    "Javascript complete history and detailed introduction in brief..",
  image: './bg.jpeg',
  imageText: 'About Javascript',
  linkText: '',
};

const featuredPosts = [
  {
    title: 'JavaScript Introduction',
    date: 'May 12',
    description:
      'Javascript introduction ..',
    image: './javascript.gif',
    imageLabel: 'About Javascript',
  },
  {
    title: 'Java History',
    date: 'May 12',
    description:
      'How java came into existence.',
    image: './java.webp',
    imageLabel: 'java',
  },
];


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
         
        </main>
      </Container>
      
    </ThemeProvider>
  );
}