import React from 'react'
import { Link } from "react-router-dom";
import './Nav.css'
import { environment } from './../environments/environments.prod'
function Nav() {
  const apiUrl=environment.apiUrl
  return (
    <>
         <nav className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-dense css-i1agjc">
         <Link
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/generateqr"
        >
          Generate QR
        </Link>
        <Link
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/javascript"
        >
          JavaScript
        </Link>
        <Link
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/reactjs"
        >
          Reactjs
        </Link>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Html
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          CSS
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Java
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Spring Framework
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Spring Boot
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Hibernate
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Spring ORM
        </a>
        <a
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          href="#"
        >
          Bootstrap
        </a>
      </nav>
    </>
  )
}

export default Nav