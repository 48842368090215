import React,{ useState} from "react";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";
import SideNav from "./SideNav";
import { useNavigate } from "react-router-dom";

function Blog3() {
  const [toggleMenu,setToggleMenu]=useState(false)
  const [headerResp, setHeaderResp] = useState(false);
  const navigate = useNavigate();

  function handleSideNav(resp) {
    if (resp) {
      setHeaderResp(resp);
    } else {
      setHeaderResp(resp);
    }
    
  }
  function navigateToPreviousPage(){
    navigate("/reactjs")
  }
  return (
    <>
      <Header data={handleSideNav} />
        {headerResp? <SideNav/> : null}
      <Nav />
      <div className="container" style={{ fontFamily: "Calibri" }}>
        <div id="props_in_reactjs">
          <h4 style={{ fontSize: "2.5em" }}>
            <b>ReactJs</b>
          </h4>
          <br />
          <br />
         
         <p style={{ fontSize: "1.7em", fontWeight: "bold" }}>
           <u>About useState Hook in ReactJs</u>
         </p>
         <p style={{ fontSize: "1.2em", textAlign: "justify" }}>
           Reactjs has a feature called hooks. In this article we will read
           about use State Hook in reactjs. So, Let's start.<br/>
           <h5><b>What is use State Hook ?</b></h5>
           useState Hook is used to manage state in functional component.
           <br />
           <br />
           <h5><b>Why to use props ?</b></h5>
           We use it to update state value on a event happens like on the click of button.
           We update state value. useState return an intial value and state function to update it's initial value
           to any thing.
<br/><br/>
           <b>State can be string, array and objects, numbers, booleans or combination of these.</b>
           
        
          <br/><br/>
           <span className="text-danger">Note:-</span> <b>On page refresh states are set to it's initial value.</b>

          </p>
        </div>
      </div>
      <div className="video">

       </div>
       <br/><br/>
       <div className="btns" style={{display:'flex',justifyContent:'space-around'}}>
       <button type="submit" onClick={navigateToPreviousPage} className="btn btn-primary">Previous</button>
       </div>
      <Footer />
    </>
  );
}

export default Blog3;
