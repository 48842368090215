import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Nav";
import SideNav from "./SideNav";
import QRCode from "react-qr-code";

function GenerateQR(props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [headerResp, setHeaderResp] = useState(false);
  const [data, setData] = useState("");
  const [hide, setHide] = useState(true);
  function handleSideNav(resp) {
    if (resp) {
      setHeaderResp(resp);
    } else {
      setHeaderResp(resp);
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.length > 0) {
      setHide(false);
    } else {
      setHide(true);
    }
  };
  return (
    <>
      <Header data={handleSideNav} />
      <Nav />
      {headerResp ? <SideNav /> : null}
      <div className="generateqr">
        <form onSubmit={handleSubmit}>
          <div className="content">
            <br />
            <label style={{ fontFamily: "calibri" }}>Content:</label>
            <br />
            <textarea
              name="content"
              style={{ border: "2px solid #3b82f680" }}
              onChange={(e) => setData(e.target.value)}
            ></textarea>
            <br />
            <br />
            <button type="submit" className="btn btn-success">
              Generate QR
            </button>
          </div>
        </form>
        <div style={{ background: "white", padding: "16px" }} hidden={hide}>
          <QRCode value={data} />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default GenerateQR;
