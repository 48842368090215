import React,{useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import './Header.css';

function Header(props) {
    const [toggleMenu,setToggleMenu]=useState(false)
    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    function handleToggleMenu(){
        if(toggleMenu){
            props.data(false)
        }else{
            props.data(true)
        }
        setToggleMenu(!toggleMenu)
    }
    const handleChange = (event) => {
      setAuth(event.target.checked);
    };
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
    <>
<Box sx={{ flexGrow: 1 }}>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
              style={{color:'black'}}
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleToggleMenu}
            sx={{ mr: 2 }}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
         
          
        </Toolbar>
      </AppBar>
    </Box>
    </>
  )
}

export default Header