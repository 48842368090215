import logo from './logo.svg';
import './App.css';
import Home from './component/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog1 from './component/Blog1';
import Blog2 from './component/Blog2';
import Blog3 from './component/Blog3';
import GenerateQR from './component/GenerateQR';

function App() {
  return (
    <div className="App">
      <BrowserRouter> 
          <Routes> 
            <Route path="/" element={<Home />}> </Route>
            <Route path="/javascript" element={<Blog1 />}> </Route>
            <Route path="/reactjs" element={<Blog2 />}> </Route>
            <Route path="/reactjs_useState" element={<Blog3 />}> </Route>
            <Route path="/generateqr" element={<GenerateQR />}> </Route>
          </Routes> 
      </BrowserRouter> 
    </div>
  );
}

export default App;
