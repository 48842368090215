import React,{ useState} from 'react'
import Header from './Header'
import Nav from './Nav'
import Footer from './Footer'
import SideNav from "./SideNav";

function Blog1() {

  const [toggleMenu,setToggleMenu]=useState(false)
  const [headerResp, setHeaderResp] = useState(false);
  function handleSideNav(resp) {
    if (resp) {
      setHeaderResp(resp);
    } else {
      setHeaderResp(resp);
    }
    
  }

  return (
    <>
        <Header data={handleSideNav} />
        {headerResp? <SideNav/> : null}
        <Nav/>
        <div className="container" style={{fontFamily:'Calibri'}}>
        <h4 style={{fontSize:'2.5em'}}><b>JavaScript History and Introduction</b></h4>
        <br/><br/>
        <p style={{fontSize:'1.7em',fontWeight:'bold'}}><u>History</u></p>
        <p style={{fontSize:'1.2em',textAlign:'justify'}}>Javascript is invented by Brendan Eich in 1995 and developed by Netscape 2, and became ECMA-262 standard in 1997
        After Netscape handed JavaScript over to ECMA, the Mozilla foundation continued to develop JavaScript for the Firefox browser.
        The planned new release (ES6) was codenamed "Harmony" (Because of the split it created?).

ES5 was a huge success. It was released in 2009, and all major browsers (including Internet Explorer) were fully compliant by July 2013.
        </p>
        </div>
        <br/><br/>
        <p style={{fontSize:'1.7em',fontWeight:'bold'}}><u>Introduction</u></p>
        <p style={{fontSize:'1.2em',textAlign:'justify'}}>Javascript is designed to manipulate dom content as it is sed to change dom (document object model) css. So, here are some of it's methods which can be used to get element value and to change the css of element.
            <ul>
                <li>document.getElementById('id').value - It is used to get the value of element</li>
                <li>document.getElementById('id').style.fontSize="35px" - It is used to set the css of element</li>
            </ul>
        </p>
        <Footer/>
    </>
  )
}

export default Blog1